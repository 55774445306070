import { inject, Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { APP, Constants } from '../shared/constants/constants';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { Cookies } from '../shared/constants/cookie.constants';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

export class AppSettings {
  constructor(public name: string,
    public theme: any,
    public stickyMenuToolbar: boolean,
    public mainToolbarFixed: boolean) { }
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  userService = inject(UserService);
  authService = inject(AuthService);
  cookieService = inject(CookieService);
  public appSettings = new AppSettings(
    'IKonnect',
    APP.LIGHT_THEME,
    true,
    false
  )

  // constructor(private cookieService: SsrCookieService) { }

  setTheme(isDark: boolean) {

    if (this.authService.isAuthenticated()) {

      this.userService.updateTheme({ isDark: isDark }).subscribe({
        next: (data) => {
          const theme = isDark ? APP.DARK_THEME : APP.LIGHT_THEME;

          this.cookieService.set(Cookies.USER_THEME, theme, {
            path: '/',
            domain: environment.cookieDomain,
            secure: true, // Recommended for HTTPS
            sameSite: 'Lax',
            expires: this.authService.getTokenExpirationDate() ?? undefined
          });

        },
        error: (error) => { },
        complete: () => {

        },
      });

    } else {
      const theme = isDark ? APP.DARK_THEME : APP.LIGHT_THEME;
      this.cookieService.set(Constants.THEME, theme, {
        path: '/',
        domain: environment.cookieDomain,
        secure: true, // Recommended for HTTPS
        sameSite: 'Lax',
        expires: this.authService.getTokenExpirationDate() ?? undefined
      });
    }

  }

  get theme() {

    if (this.authService.isAuthenticated()) {
      const theme = this.cookieService.get(Cookies.USER_THEME) ? this.cookieService.get(Cookies.USER_THEME) : this.appSettings.theme;
      return theme;
    }

    const theme = this.cookieService.get(Cookies.THEME) ? this.cookieService.get(Cookies.THEME) : this.appSettings.theme;
    return theme;
  }
}
